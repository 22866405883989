import { FC, ReactNode, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";

import HeaderFilter from "./HeaderFilter";

import FleetCard from "components/FleetCard/FleetCard";

import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";
import { CarListingModal } from "models/vehicleModal";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Our Fleet",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"],
}) => {
  const { ourVehicles, ourVehiclesLoading, setSelectedCar } = useData();
  const [carsLoading, setCarsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const renderCard = (car: CarListingModal) => (
    <FleetCard
      key={car.id}
      data={car}
      onClick={() => {
        navigate("/car-listing-detail");
        setSelectedCar(car);
      }}
    />
  );

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {ourVehiclesLoading ? (
          <div className="flex justify-center items-center col-span-full">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-300"></div>
          </div>
        ) : ourVehicles && ourVehicles?.length > 4 ? (
          ourVehicles?.slice(0, 4).map((car) => renderCard(car))
        ) : (
          ourVehicles?.map((car) => renderCard(car))
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
