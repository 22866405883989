"use client";

import { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import RentalCarTimesRangeInput from "./RentalCarTimesRangeInput";
import DropOffLocationInput from "../DropOffLocationInput";
import { useData } from "data/data-provider";

export interface RentalCarSearchFormProps {}

const RentalCarSearchFormMobile: FC<RentalCarSearchFormProps> = () => {
  const {
    dropOffLocation,
    setPickupLocation,
    setDropoffLocation,
    setSearchedPickupLocation,
    setSearchedDropoffLocation,
    pickupLocation,
    setDropoffLocationType,
    setSameDropoffLocation,
    dropoffLocationType,
  } = useData();

  const renderRadioBtn = () => {
    return (
      <div className="flex items-center flex-wrap flex-row  border-neutral-100 dark:border-neutral-700 mb-4">
        <div className="grid grid-cols-2 gap-4 w-full bg-[#E5E7EB] rounded-full">
          <div
            className={`p-0 md:p-4 flex items-center justify-center rounded-full cursor-pointer text-xs leading-6 text-left m-1 ${
              dropoffLocationType === true
                ? "bg-primary-200 text-white shadow-black/10 opacity-100 font-bold"
                : " text-black"
            }`}
            onClick={(e) => {
              setDropoffLocationType(true);
              setDropoffLocation(pickupLocation);
              setSearchedDropoffLocation(pickupLocation?.name ?? "");
              setSameDropoffLocation(true);
              setPickupLocation(null);
              setDropoffLocation(null);
              setSearchedPickupLocation("");
              setSearchedDropoffLocation("");
            }}
          >
            Same Drop Off
          </div>
          <div
            className={`py-2 flex items-center justify-center rounded-full text-xs leading-6 text-left cursor-pointer m-1 ${
              dropoffLocationType === false
                ? "bg-primary-200 text-white  shadow-black/10 font-bold "
                : "text-black"
            }`}
            onClick={() => {
              setDropoffLocationType(false);
              setSameDropoffLocation(false);
              // Reset values
              setPickupLocation(null);
              setDropoffLocation(null);
              setSearchedDropoffLocation("");
              setSearchedPickupLocation("");
            }}
          >
            Different Drop Off
          </div>
        </div>
      </div>
    );
  };
  const isDdropOffdifferent = dropoffLocationType === false;

  return (
    <form className="w-full relative p-8 rounded-[35px] shadow-xl dark:shadow-2xl bg-white ">
      {renderRadioBtn()}
      <div
        className={`relative flex flex-col  ${
          isDdropOffdifferent ? "md:grid md:grid-cols-1 md:gap-2" : ""
        }`}
      >
        <div className={`${isDdropOffdifferent ? " " : "w-full my-2"}`}>
          <LocationInput
            placeHolder="Pick Up Location"
            className="w-full bg-white rounded-xl"
            sameDropOffLocation={dropoffLocationType === false}
          />
        </div>
        {isDdropOffdifferent && (
          <div className="my-2">
            <DropOffLocationInput
              placeHolder="Dropoff Location"
              className="w-full bg-white rounded-xl"
              divHideVerticalLineClass="-inset-x-0.5"
            />
          </div>
        )}
      </div>
      <div className="relative flex flex-col">
        <RentalCarDatesRangeInput className="w-full bg-white rounded-xl my-2" />

        <RentalCarTimesRangeInput
          dropOffLocationType={dropoffLocationType}
          className="w-full bg-white rounded-xl my-2"
        />
      </div>
    </form>
  );
};

export default RentalCarSearchFormMobile;
